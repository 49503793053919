/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, MenuItem, Select } from "@material-ui/core";
import { AddCircleOutline, GrainOutlined, RemoveCircleOutline } from '@material-ui/icons';
import { getStorage, setStorage } from "cache";
import LeftPanel from "components/LeftPanel";
import PanelHeader from "components/PanelHeader";
import RightPanel from "components/RightPanel";
import { SelectFieldStyled } from "components/RightPanel/styled";
import FilterOrbit from "components/filter";
import { ContainerRight } from "components/filter/styled";
import { useData } from "contexts/dataContext";
import { ContextMenuContext } from "contexts/rightClickContext";
import * as d3 from "d3";
import { MainDiv } from "pages/Canvas/styled";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HundredPSVG } from "ui-components/canvas-d3-components";
import SvgDefs from "ui-components/canvas-d3-components/defs";
import { ZoomButtonsPanel } from "ui-components/styleds";
import { useCanvasContext } from "../../contexts/canvasContext";
import useLeftPanelDragHandlers from "./LeftPanelDragHandlersHook/LeftPanelDragHandlersHook";
import { useOnPageRightClick } from "./RightClickHandlers/OnPageRightClick";
import { useDraw } from "./d3UseEffects/draw";
import { useSetupD3 } from "./d3UseEffects/setupD3";
import { ContainerBottomRight } from "./styled";

// eslint-disable-next-line react/prop-types
export function D3Canvas ({ open, handleDrawerClose, handleDrawerOpen }): React.ReactElement {
    const ref = useRef(null);
    const { t } = useTranslation();

    const contextMenuState = useContext(ContextMenuContext);
    const { reload: refetchPrimInfo, zoom, setZoom, setItemSelected, server, setServer } = useData();
    const [isShowDashboard, setIsShowDashboard] = useState(true);
    const [enablePhysicsEffect, setEnablePhysicsEffect] = useState(false);
    const { container, d3Data, data } = useCanvasContext();

    useSetupD3({ ref, isShowDashboard, setEnablePhysicsEffect, enablePhysicsEffect });

    const zoomFromTo = [
        { k: 25, v: 0.25 },
        { k: 50, v: 0.5 },
        { k: 100, v: 1 },
        { k: 150, v: 1.5 },
        { k: 200, v: 2 }
    ];

    const [zoomSelected, setZoomSelected] = useState(100);
    const [nearZoom, setNearZoom] = useState(100);

    const onDropAndDropHandler = useLeftPanelDragHandlers();
    const onPageRightClick = useOnPageRightClick(isShowDashboard);

    useDraw();

    useEffect(() => {
        setItemSelected(null)
    }, [isShowDashboard])

    useEffect(() => {
        const currentMode = getStorage<boolean>("isShowDashboard");
        setIsShowDashboard(Boolean(currentMode));
    }, []);

    // reload memory info with current orbits and relationships
    const onChangeSwitch = (checked: boolean): void => {
        const newDataCollection = { ...server }

        // Nodes
        newDataCollection.niamCustomMutations = [...data.customMutations]
        newDataCollection.niamTags = [...data.tags]
        newDataCollection.niamObjects = [...data.objects]
        newDataCollection.niamInterfaces = [...data.interfaces]
        newDataCollection.niamScripts = [...data.scripts]
        newDataCollection.niamEnums = [...data.enums]
        newDataCollection.niamNodeRelationships = [...data.nodeRelationships]

        // Relationships
        newDataCollection.primPropertiesRelLinks = [...data.relLinks]

        setServer(newDataCollection);

        setStorage("isShowDashboard", checked);
        setIsShowDashboard(checked);
    };

    useEffect(() => {
        const counts = zoomFromTo.map((z) => z.v);
        const goal = zoom.k;

        const closest = counts.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal)
            ? curr
            : prev));

        const item = zoomFromTo.find((f) => f.v === closest);
        setNearZoom(item.k);
    }, [zoom]);

    useEffect(() => {
        setZoomSelected(nearZoom);
    }, [nearZoom]);

    useEffect(() => {
        if (container) {
            const item = zoomFromTo.find((f) => f.k === zoomSelected);

            d3.zoom()
                .scaleExtent([0.1, 3])
                .on("zoom", (event) => {
                    contextMenuState.setContextMenuState({
                        visibility: false
                    });

                    setZoom(event.transform);
                    d3Data.g.attr("transform", event.transform);
                })
                .on("end", (event) => {
                    setZoom(event.transform);

                    // Keep Zoom updated with new positions
                    (container as any).call(
                        d3.zoom().transform,
                        d3.zoomIdentity.translate(event.transform.x, event.transform.y).scale(event.transform.k)
                    );
                }).scaleTo((container as any).transition().duration(500), item.v);
        }
    }, [zoomSelected]);

    const selectZoom = (e: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
        setZoomSelected(Number(e.target.value));
    };

    const decreaseZoom = (): void => {
        const i = zoomFromTo.findIndex((f) => f.k === zoomSelected);
        const beforeItem = i === 0
            ? zoomFromTo[0]
            : zoomFromTo[i - 1];

        setZoomSelected(beforeItem.k);
    };

    const increaseZoom = (): void => {
        const i = zoomFromTo.findIndex((f) => f.k === zoomSelected);
        const afterItem = i >= zoomFromTo.length - 1
            ? zoomFromTo[zoomFromTo.length - 1]
            : zoomFromTo[i + 1];

        setZoomSelected(afterItem.k);
    };

    const rearrangeBtn = () => {
        const getCenterPositionByZoomed = (k): [number, number] => {
            let x = 0
            let y = 0

            if (zoom.k == "0.5") {
                x = window.innerWidth * zoom.k / 2
                y = window.innerHeight * zoom.k / 2
            }

            if (zoom.k == "0.25") {
                x = window.innerWidth * 0.7 / 2
                y = window.innerHeight * 0.7 / 2
            }

            return [x, y]
        }

        const [nX, nY] = getCenterPositionByZoomed(zoom.k)

        // Centralize after rearrange
        const transformCenter: any = zoom.k >= 1 ? { k: 1, x: nX, y: nY } : { k: zoom.k, x: nX, y: nY }

        setZoom(transformCenter);
        d3Data.g.transition().duration(500).attr("transform", `translate(${transformCenter.x}, ${transformCenter.y}) scale(${transformCenter.k})`);

        // Keep Zoom updated with new positions
        (container as any).call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(transformCenter.x, transformCenter.y).scale(transformCenter.k)
        );

        setEnablePhysicsEffect(true);
    }

    return (
        <MainDiv>
            <PanelHeader
                refetchPrimInfo={refetchPrimInfo}
                container={container}
                d3Data={d3Data}
                data={data}
                showServerOptions={true}
                open={open}
                handleDrawerClose={handleDrawerClose}
                handleDrawerOpen={handleDrawerOpen}
            />

            <LeftPanel isShowDashboard={isShowDashboard} />

            <RightPanel isShowDashboard={isShowDashboard} />

            <HundredPSVG
                id="rootSVG"
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setItemSelected(null)

                    contextMenuState.setContextMenuState({
                        visibility: false
                    });
                }}
                onContextMenu={onPageRightClick}
                onDrop={onDropAndDropHandler.onDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                <SvgDefs />
            </HundredPSVG>

            <ContainerBottomRight>
                <ContainerRight>
                    <Button disabled={!isShowDashboard} data-cy="d3canvasPage_btnRearrange" onClick={() => rearrangeBtn()} style={{ marginTop: 5, marginRight: 10, padding: 3, width: 125, height: 40, border: '1px solid #BBB' }}>
                        <GrainOutlined style={{ width: 32, height: 32 }} />
                        Rearrange
                    </Button>

                    <SelectFieldStyled
                        size="small"
                        style={{ marginTop: 5, padding: 0 }}
                        value={isShowDashboard ? t("panel:show.mode.dashboard"): t("panel:show.mode.policy")}
                        label="View"
                        onChange={(e) => {
                            onChangeSwitch(e.target.value === "Model")
                    }}>
                        <MenuItem value="Ontology">
                            {t("panel:show.mode.policy")}
                        </MenuItem>

                        <MenuItem value="Model">
                            {t("panel:show.mode.dashboard")}
                        </MenuItem>
                    </SelectFieldStyled>
                </ContainerRight>

                <ZoomButtonsPanel style={{ margin: "0 10px" }}>
                    <RemoveCircleOutline
                        data-cy="d3canvasPage_btnDecreaseZoom"
                        style={{ width: 35, height: 35 }}
                        onClick={() => {
                            decreaseZoom();
                        }}
                    />

                    <Select
                        value={zoomSelected}
                        onChange={(e) => {
                            selectZoom(e);
                        }}
                    >
                        {zoomFromTo.map((z) => (
                            <MenuItem key={z.k} value={z.k}>
                                {z.k}
                                %
                            </MenuItem>
                        ))}
                    </Select>

                    <AddCircleOutline
                        style={{ width: 35, height: 35 }}
                        onClick={() => {
                            increaseZoom();
                        }}
                    />
                </ZoomButtonsPanel>

                <FilterOrbit style={{ margin: "0 10px" }} isShowDashboard={isShowDashboard} />
            </ContainerBottomRight>
        </MainDiv>
    );
}
